<template>
    <div class="carousel-list">
        <a-carousel autoplay>
            <div v-for="(img,imgIndex) in imgList" :key="imgIndex">
                <img class="carousel-img" :src="img" alt="">
            </div>
        </a-carousel>
    </div>
</template>
<script>
export default {
    name: 'CarouselList',
    props: {
        imgList: {
            type: Array,
            default: () => {
                return []
            }
        }
    }
}
</script>
<style lang="less" scoped>
.carousel-img {
    width: 100vw;
}
</style>